import { TextInput } from '@mantine/core'

import IconSearch from 'src/icons/IconSearch'

export interface SearchBoxProps {
  value: string
  setValue: (value: string) => void
  placeholder?: string
  onSearch: (value: string) => void
}
export const SearchBox = ({
  value,
  setValue = () => {},
  placeholder = '',
  onSearch,
}: SearchBoxProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(value)
    }
  }

  return (
    <TextInput
      className="flex-1"
      leftSection={
        <IconSearch className="fill-none stroke-doubleNickel-gray-500" />
      }
      placeholder={placeholder}
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      onKeyDown={handleKeyPress}
    />
  )
}
